import React from "react";
import SistentHome from "../../../sections/Projects/Sistent/index";

const SistentIndexPage = () => {
  return (
    <>
      <SistentHome />
    </>
  );
};

export default SistentIndexPage;
